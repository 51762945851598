export default function filterReleasePosts(allPosts, post, from) {
  const olderReleases = allPosts.edges.map(e => e.node);
  const releases = [];

  // Skip build, the code should only run on browser side only
  if (typeof window !== "undefined") {
    let fromIsFound;
    if (from) {
      // from should be 1.7.10
      from = from.replace(/-/g, ".");
      let include = false;
      for (let index = 0; index < olderReleases.length; index++) {
        const release = olderReleases[index];

        if (release.frontmatter.version === post.frontmatter.version) {
          // Include next release (post.frontmatter.version should be excluded)
          include = true;
        } else if (release.frontmatter.version === from) {
          // Stop including (from should be excluded)
          include = false;
          fromIsFound = true;
        } else if (include) {
          releases.push(release);
        }
      }
    }

    if (!fromIsFound) {
      // empty the array
      releases.length = 0;
    }
  }

  return releases;
}
