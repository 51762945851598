import React, { Fragment } from "react";

export const FormattedDate = ({ value, locale }) => (
  <Fragment>{new Date(value).toLocaleDateString(locale)}</Fragment>
);

export const FormattedDateTime = ({ value, locale }) => (
  <Fragment>{new Date(value).toLocaleString(locale)}</Fragment>
);

// new Date(date).toLocaleDateString(locale, { month: "long", year: "numeric" })