import React, { Fragment, useEffect, useState } from "react";
import qs from "qs";
import { graphql, Link } from "gatsby";
import { FormattedMessage, injectIntl } from "react-intl";
import { useLocation } from "@reach/router";
import Layout from "../components/layout";
import { Author } from "../components/author";
import renderAst from "../utils/ast-compiler";
import { I18Link } from "../components/i18link";
import { FormattedDate } from "../components/date";
import Button from "../components/button";
import filterReleasePosts from "../../lib/release-post-adapters";

const DownloadButton = ({ className }) => (
  <I18Link id="download" className={className}>
    <Button color="indigo" inverted>
      <svg
        className="-ml-1 mr-2 h-5 w-5"
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
        />
      </svg>
      <FormattedMessage id="release.download-button" />
    </Button>
  </I18Link>
);

const UpgradeButton = ({ className }) => (
  <I18Link id="how-to-update-latest" className={className}>
    <Button color="indigo" inverted>
      <svg
        className="-ml-1 mr-2 h-5 w-5"
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
        />
      </svg>
      <FormattedMessage id="release.how-to-upgrade" />
    </Button>
  </I18Link>
);
const WarningForLatestRelease = ({ version, to }) => (
  <div className="rounded-md bg-pink-50 p-4 mt-6">
    <div className="flex">
      <div className="flex-shrink-0">
        <svg
          className="h-5 w-5 text-pink-400"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          aria-hidden="true"
        >
          <path
            fill-rule="evenodd"
            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
            clip-rule="evenodd"
          />
        </svg>
      </div>
      <div className="ml-3 flex-1 md:flex md:justify-between">
        <p className="text-sm text-pink-700">
          <FormattedMessage id="release.new-version-warning.header" />
        </p>
        <p className="mt-3 text-sm md:mt-0 md:ml-6">
          <Link
            to={to}
            className="whitespace-nowrap font-medium text-pink-700 hover:text-pink-600"
          >
            <FormattedMessage
              id="release.new-version-warning.content"
              values={{
                version,
              }}
            />
            <span aria-hidden="true">&rarr;</span>
          </Link>
        </p>
      </div>
    </div>
  </div>
);

const ReleasePage = ({
  pageContext: { language, title, id, featuredImage },
  data,
  intl,
}) => {
  const [releases, setReleases] = useState([]);
  const location = useLocation();

  const { from } = qs.parse(location.search, { ignoreQueryPrefix: true });
  const { post, allPosts } = data;

  const isSummary = location ? /summary/.test(location.pathname) : false;

  // Use effect otherwise reconcialiation issues occur
  useEffect(() => {
    setReleases(filterReleasePosts(allPosts, post, from));
  }, [allPosts, post, from]);

  const isLatest = post.frontmatter.tags.includes("latest");

  return (
    <Layout
      locale={language}
      title={title}
      pageId={id}
      featuredImage={featuredImage}
    >
      <div className="divide-y-2 divide-gray-200">
        <div className="bg-white">
          <div className="max-w-screen-xl mx-auto  px-4 sm:pt-8 sm:pb-2 sm:px-6 lg:px-8">
            <div className="text-base text-pink-600 font-semibold tracking-wide uppercase">
              <FormattedMessage id="release.whatsnew" />
            </div>
            <h1 className="mt-1 text-4xl leading-10 font-extrabold text-gray-900 sm:text-5xl sm:leading-none sm:tracking-tight lg:text-6xl">
              {post.frontmatter.title}
            </h1>
            {isLatest && (
              <span className="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-pink-100 text-pink-800">
                <FormattedMessage id="release.tags.latest" />
              </span>
            )}
            <Author.Line
              id={post.frontmatter.author}
              text={post.frontmatter.date}
            />
          </div>
        </div>
        <div className="px-4 sm:px-6 lg:px-8  pb-4">
          {!isLatest && (
            <WarningForLatestRelease
              to={data.latestReleaseNoteLink.edges[0].node.path}
              version={data.latest.frontmatter.version}
            />
          )}
          <div className="prose">{renderAst(post.htmlAst)}</div>
        </div>

        {releases.map((olderRelease) => (
          <div key={olderRelease.fields.id}>
            <h4>{olderRelease.frontmatter.version}</h4>
            {renderAst(olderRelease.htmlAst)}
          </div>
        ))}

        <div className="px-4 sm:px-6 lg:px-8 py-4">
          <UpgradeButton />
          <DownloadButton className="ml-2" />
        </div>
      </div>
    </Layout>
  );
};

export default ReleasePage;

export const query = graphql`
  query ReleasePageQuery($slug: String!, $language: String!) {
    post: markdownRemark(fields: { slug: { eq: $slug } }) {
      htmlAst
      frontmatter {
        title
        date
        tags
        author
        version
      }
      fields {
        id
      }
    }

    allPosts: allMarkdownRemark(
      filter: { frontmatter: { tags: { eq: "release" } } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          htmlAst
          frontmatter {
            title
            date
            tags
            author
            version
          }
          fields {
            id
          }
        }
      }
    }

    latest: markdownRemark(frontmatter: { tags: { eq: "latest" } }) {
      frontmatter {
        version
        date
      }
    }

    latestReleaseNoteLink: allSitePage(
      filter: {
        context: { tags: { eq: "latest" }, language: { eq: $language } }
      }
    ) {
      ...LanguageMap
    }
  }
`;
